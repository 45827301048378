#form-ricerca .ant-input-affix-wrapper {
    border-color: #D1C7BB;
}

#form-ricerca .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
}

#form-ricerca .ant-input-affix-wrapper>input.ant-input {
    color: black;
    font-size: 'Roboto', sans-serif;
    font-weight: 600;
}

#form-ricerca .ant-input-search-button:not(.ant-btn-primary) {
    color: black
}

#form-ricerca .ant-input-search-button {
    border-start-start-radius: 4px;
    border-end-start-radius: 4px;
}

#form-ricerca .ant-input-group .ant-input-affix-wrapper:not(:disabled):hover {
    border-right: 0px;
}

/*
 (first value applies to top-left corner, second value applies to top-right corner, third value applies to bottom-right corner, and fourth value applies to bottom-left corner):


*/
#form-ricerca .ant-btn {
    border-radius: 0px 4px 4px 0px;
    border-left: 0px;
    border-color: #D1C7BB;
}

#form-ricerca .ant-btn-default:not(:disabled):hover {
    color: black;
    border-left: 0px;
}

#form-ricerca .ant-select-selector {
    border-radius: 4px;
    border-left: 0px;
    border-color: #D1C7BB;
    font-size: 'Roboto',sans-serif;
    font-weight: 600;
    background: white;
}
#form-ricerca  .ant-select-selection-placeholder {
    color: black;
}

#form-ricerca .ant-select-selector .ant-select-selection-item {
    color: black;
    font-size: 'Roboto',sans-serif;
    font-weight: 600;
}

#form-ricerca .ant-select-arrow .anticon {

    color: black;
}