.ant-tree-title {
    color: black;
    font-family: 'Roboto', sans-serif;
    font-size: "18px";
    font-weight: 600;
}

.ant-tree-treenode {
    padding: 0 0 12px 0 !important;
}

.ant-tree-treenode-switcher-open {
    color: #E7B25E;
}

.ant-tree-treenode-switcher-open span {
    color: #E7B25E;
    text-decoration: underline;
}

.ant-tree-node-selected {
    background-color: transparent !important;
    color: #E7B25E;
}

.ant-tree-node-selected span {
    color: #E7B25E;
    text-decoration: underline;
}

#form-invia-doc label {
    color: #818386;
}

.ant-spin-dot-item {
    background-color: #EC1C24 !important;
}

input:-webkit-autofill,
 input:-webkit-autofill:hover,
 input:-webkit-autofill:focus,
 input:-webkit-autofill:active {
    /* box-shadow: 0 0 0 30px var(--grey-900) inset !important; */
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

input:-webkit-autofill {
  
    border-color:#d9d9d9ed;
}