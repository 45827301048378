.ant-pagination .ant-pagination-item a {
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    font-size: 16px;

}

.ant-pagination .ant-pagination-next .ant-pagination-item-link{
    font-weight: 600;
    font-size: 16px;

}

.ant-pagination .ant-pagination-prev .ant-pagination-item-link{
    font-weight: 600;
    font-size: 16px;
}
.ant-pagination .ant-pagination-item-active {
    font-weight: 600;
    background-color: #EC1C24;
    border-color: #EC1C24;
    color: white;
    font-family: 'Roboto', sans-serif;
}
.ant-pagination .ant-pagination-item-active a {

    color: white;
   
}

.ant-pagination .ant-pagination-item{border-radius: 0px;} 

.ant-pagination .ant-pagination-item-active:hover {
    border-color: #EC1C24;;
}

.ant-pagination .ant-pagination-item-active a:hover{
    border-color: #EC1C24;;
}

.ant-pagination .ant-pagination-item-active:hover a {
    color: white;
}